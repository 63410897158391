export default class Competition {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.image = ''
    this.imageUrl = null
    this.about_url = ''
    this.start_date = ''
    this.end_date = ''
    this.country_id = null
    this.city_id = null
    this.sponsors = []
    this.sponsorsItems = []
    this.translations = [
      { id: null, name: '', about: '', lang: 'ar' },
      { id: null, name: '', about: '', lang: 'en' }
    ]
  }

  fillData (data) {
    if (data) {
      const itemAr = data.translations?.find(o => o.lang === 'ar')
      const itemEn = data.translations?.find(o => o.lang === 'en')

      this.imageUrl = data.image ? data.image : null
      this.about_url = data.about_url ? data.about_url : ''
      this.start_date = data.start_date ? data.start_date : ''
      this.end_date = data.end_date ? data.end_date : ''
      this.country_id = data.country_id ? data.country_id : null
      this.city_id = data.city_id ? data.city_id : null
      this.sponsors = data.sponsors ? data.sponsors.map(i => i.id) : []
      this.sponsorsItems = data.sponsors ? data.sponsors : []
      this.translations[0].id = itemAr ? itemAr.id : null
      this.translations[0].name = itemAr ? itemAr.name : ''
      this.translations[0].about = itemAr ? itemAr.about : ''
      this.translations[1].id = itemEn ? itemEn.id : null
      this.translations[1].name = itemEn ? itemEn.name : ''
      this.translations[1].about = itemEn ? itemEn.about : ''
    } else {
      this.setInitialValue()
    }
  }
}

<template>
  <div>
    <b-card>
      <ValidationObserver ref="competition" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
          <b-row>
            <b-col md="12">
              <!-- <input-file v-model="competition.image" :defaultUrl="competition.imageUrl" :name="$t('global.image')" :label="$t('global.image')" :validate="currentPage == itemPages.create ? '' : ''" :disabled="disableStatus" /> -->
              <cropper-images
                :label="$t('global.coverImage')"
                nameOfImage="image.jpg"
                @cropper-save="
                  competition.image = $event.file ? $event.file : null
                "
                :multi="false"
                :imageUrl="competition.imageUrl"
                :ratio="1"
                :disabled="disableStatus"
                required
              />
            </b-col>

            <b-col md="6">
              <input-form
                v-model="competition.translations[0].name"
                :name="$t('competitions.nameAr')"
                :label="$t('competitions.nameAr')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <input-form
                v-model="competition.translations[1].name"
                :name="$t('competitions.nameEn')"
                :label="$t('competitions.nameEn')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="6">
              <textarea-form
                v-model="competition.translations[0].about"
                :name="$t('competitions.aboutAr')"
                :label="$t('competitions.aboutAr')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <textarea-form
                v-model="competition.translations[1].about"
                :name="$t('competitions.aboutEn')"
                :label="$t('competitions.aboutEn')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="12">
              <input-form
                v-model="competition.about_url"
                :name="$t('competitions.aboutUrl')"
                :label="$t('competitions.aboutUrl')"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="6">
              <date-time-picker
                v-model="competition.start_date"
                :name="$t('competitions.startDate')"
                :label="$t('competitions.startDate')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <date-time-picker
                v-model="competition.end_date"
                :name="$t('competitions.endDate')"
                :label="$t('competitions.endDate')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="12">
              <main-select
                :multiple="true"
                :options="sponsors"
                label="name"
                v-model="competition.sponsors"
                :reduce="(i) => i.id"
                :labelTitle="$t('sponsors.items')"
                :showAsInfo="disableStatus"
              />
            </b-col>

            <b-col md="6">
              <main-select
                :options="countries"
                label="name"
                v-model="competition.country_id"
                :reduce="(i) => i.id"
                :text="competition.countryName"
                :labelTitle="$t('competitions.country')"
                validate="required"
                :showAsInfo="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <main-select
                :options="cities"
                label="name"
                v-model="competition.city_id"
                :reduce="(i) => i.id"
                :text="competition.cityName"
                :labelTitle="$t('competitions.city')"
                validate="required"
                :showAsInfo="disableStatus"
              />
            </b-col>
          </b-row>

          <form-btns
            v-if="currentPage != itemPages.view"
            :label="submitLabel"
            :loading="loading"
          />
        </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view">
      <sponsors
        v-if="competition.sponsorsItems.length > 0"
        :items="competition.sponsorsItems"
        :titleStatus="true"
      />
      <races :competitionId="id" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import competitionServices from "../services/competitions";
import sponsorsService from "../../sponsors/services/sponsors";
import countriesService from "../../../generalSettings/countries/services/countries";
import citiesService from "../../../generalSettings/cities/services/cities";
import Competition from "../models/Competition";
import Sponsors from "../../sponsors/views/Sponsors.vue";
import Races from "../../races/views/Races.vue";
import { objectToFormData } from "../../../../Utils/helper";
import moduleItemMixin from "../../../../Mixins/moduleItemMixin";

export default {
  mixins: [moduleItemMixin],
  components: { Sponsors, Races },
  data() {
    return {
      competition: new Competition(),
      sponsors: [],
      countries: [],
      cities: [],
    };
  },
  methods: {
    async create() {
      console.log("lastRoute: ", this.lastRoute);
      const formData = objectToFormData(this.competition);
      competitionServices
        .create(formData)
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "events.competitions" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async update() {
      const formData = objectToFormData(this.competition);
      competitionServices
        .update(this.id, formData)
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "events.competitions" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    core.index();
  },
  created() {
    console.log("history: ", history);
    if (this.id) {
      competitionServices.findOne(this.id).then((response) => {
        this.competition.fillData(response.data.data);
        this.loading = false;
      });
    } else {
      this.loading = false;
    }

    if (!this.disableStatus) {
      sponsorsService.findAll().then((res) => {
        this.sponsors = res.data.data;
      });
      countriesService.findAll().then((res) => {
        this.countries = res.data.data;
      });
      citiesService.findAll().then((res) => {
        this.cities = res.data.data;
      });
    }
  },
};
</script>

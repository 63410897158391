import api from "@/axios";

export default {
  findAll() {
    return api().get("competitions");
  },
  findOne(id) {
    return api().get(`admin/competition/${id}`);
  },
  create(data) {
    return api().post("admin/competition/add", data);
  },
  update(id, data) {
    return api().patch(`admin/competition/${id}`, data);
  },
  remove(id) {
    return api().delete(`admin/competitions/${id}`);
  },
};
